import { Carousel, LazyImageProvider } from "@jetprivilege/web/dist/carousel";
import {Button} from '@jetprivilege/web/dist/button';
import Moment from 'react-moment';
import {RedirectUrl,OfferRedirect} from "./offerFunctions"
import {AdobeofferClickEvent} from "../../Common_JS/homePage/homePageAdobe"
import { clevertapOfferClickEvent } from "../../Common_JS/homePage/homePageCleverTap";

export default function OffersComponent(props) {

    return (
        <>
            {((props.offers?.offer !== undefined) && props.offers?.offer.length > 0) ?
                <section className='jp-common-section mtb-48 ptb-0'>
                    <div className="jp-container">
                        <div className={["jp-row section-apt", props.css["offery-sec"]].join(" ")}>
                            <div className='offer-head'>
                                <div className="jp-row">
                                <div className="jp-col-lg-1 jppl-hidden-sm"></div>
                                <div className={["jp-col-lg-10 jppl-col-sm-12 jp-common-section-title-wrap ", props.css["paddingarea"], props.css["same-head-h2"], props.css["class-same-line-fix"]].join(" ")}>
                                    <h2 className={[props.css["jp-common-section-title"], props.css["t"], props.css["jp-heading"], "jp-heading"].join(" ")}>
                                        {props.sectionTitle}
                                    </h2>
                                        <div className={props.css['view-all-link-desktop']}>
                                            <Button buttonType="link" onClick={() => {
                                                RedirectUrl("https://www.intermiles.com/offers-deals/hotels")
                                                AdobeofferClickEvent('',)
                                                }}>
                                            View all hotel offers
                                            </Button>
                                        </div>
                                    </div>
                                </div> 
                            </div> 
                            <div className="jp-col-lg-1 jppl-hidden-sm"></div>
                            <div className={'jp-col-lg-10  jppl-col-sm-12 jp-inner-col-p-7 paddingarea'}>
                                <div>
                                    <LazyImageProvider imagesToPreload={1}>
                                        <Carousel
                                            containerClass="container nested_carousel offer_carousel"
                                            responsive={{
                                                desktop: {
                                                    breakpoint: {
                                                        max: 3000,
                                                        min: 200,
                                                    },
                                                    items: 2.3,
                                                    partialVisibilityGutter: 0,
                                                },
                                            }}
                                            // showDots
                                            partialVisible
                                            arrows
                                        >
                                            {
                                             props.offers?.offer.map((element, id) => {

                                                    return (
                                                        <div key={id} className={[props.css["hpcolum"], props.css["offer-element"]].join(" ")} onClick={() => {OfferRedirect(element);AdobeofferClickEvent(element,id);}}>
                                                                {/* {console.log("elementttt--",element)} */}
                                                            <div className={["jp-property-type-card jp-position-relative", props.css["offer-section"]].join(" ")}>
                                                                <figure className={[props.css["jp-property-img-wrap"], "jp-property-img-wrap jp-hotel-slider-wrap jp-position-relative"].join(" ")}>
                                                                    <img
                                                                        src={element.offerThumbnailImage}
                                                                        alt={element.offerImageAltText}
                                                                        onClick={() => { clevertapOfferClickEvent(element,id)}}
                                                                    />
                                                                </figure>
                                                                {
                                                                    element.offerTagExpiringSoon &&
                                                                    <div className={[props.css["offer-tag"], props.css["expiring-soon"]].join(" ")}>
                                                                        Expiring Soon
                                                                </div>
                                                                }
                                                                {
                                                                    element.offerTagNewlyAdded &&
                                                                    <div className={[props.css["offer-tag"], props.css["newly-added"]].join(" ")}>
                                                                        Newly Added
                                                                </div>
                                                                }
                                                                <div className={props.css["offerTitle"]}>
                                                                    <h3>{element.offerTitle}</h3>
                                                                </div>
                                                                <div className={["valid-duration", props.css["valid-duration"]].join(" ")}>
                                                                    {`Validity: `}
                                                                    <Moment format="DD">
                                                                        {element.offerStartFrom}
                                                                    </Moment>
                                                                    {` - `}
                                                                    <Moment format="DD MMM YYYY">
                                                                        {element.offerValidity}
                                                                    </Moment>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })


                                            }
                                        </Carousel>
                                    </LazyImageProvider>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                :
                ""
            }
        </>
    )
}