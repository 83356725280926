import { addDays, format } from "date-fns";
import { utmUrlParam } from "../../services/commonUrlParams";
import config from "../../config/config_frontend.json"

module.exports.RedirectUrl=function(data) {
    window.location.href = data;
}


module.exports.OfferRedirect=function(data) {

    let checkin = format(addDays(new Date(), 1), 'yyyy-MM-dd');
    let checkout = format(addDays(new Date(), 2), 'yyyy-MM-dd');
    let utmUrl = utmUrlParam(window.location.href);
    let urlString = 'offerdetails?offerContentId=' + data.offerUniqueId + "&checkin=" + checkin + "&checkout=" + checkout + utmUrl
    
    window.location.href = config.Localhost+urlString;
}

